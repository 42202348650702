import React, { useEffect, useState } from 'react';
import { Flex, ListTable, Typo, Avatar, IconAsButton, theme } from '../../../ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const EditableCoursesList = ({courses}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [editableCourses, setEditableCourses] = useState([]);

  useEffect(() => {
    const filteredCourses = courses.map((course)=>{
      if(course.canEdit) return course
      else return null;
    }).filter(Boolean);

    setEditableCourses(filteredCourses)

  },[courses])

  const navugateToCourseSettings = (item)=>{
    navigate(`/courses/${item.id}/settings`);
  }


  const navigateToCourse = (item)=>{
    navigate(`/view/course/${item.id}`)

  }

  const navigateToEditCourse = (item)=>{
    navigate(`/course/${item.ownerSlug}/${item.slug}`, { state: item });
  }

  const toggleCourseDraft = (item) => {

    console.log('TOGGLE DRAFT')

  }

  const [tableInfo, setTableInfo] = useState([
    {
      label: t('globals.name'), hasSorting: { sortedMethod: 'string', propVal: 'name' }, data: (item) => {
        return <Flex gap='10'>
          <Avatar url={item.coverImage.url} size='small'>{item.name.substr(0, 1)}</Avatar>
          <div>
            <Typo.Text>{item.name}</Typo.Text>
            <Typo.SmallText>{item.ownerName}</Typo.SmallText>
            <Typo.SmallText>{`course id: ${item.id}`}</Typo.SmallText>
          </div>
        </Flex>
      }
    },
    { label: 'Status', data: (item) => !!item.draft
        ? <Flex gap='10' onClick={(e) => toggleCourseDraft(e, item)}>
          <IconAsButton tooltipLabel='show as completed' iconName='ToggleOff' color={theme.colors.NEUTRAL_60} onClick={(e) => toggleCourseDraft(e, item)}/>
          <Typo.Text $overflowWrap='normal' >Draft</Typo.Text>
        </Flex>
        : <Flex gap='10' onClick={(e) => toggleCourseDraft(e, item)}>
          <IconAsButton  tooltipLabel='show as draft' iconName='ToggleOn' color={theme.colors.PRIMARY} onClick={(e) => toggleCourseDraft(e, item)}/>
          <Typo.Text $overflowWrap='normal'>Complete</Typo.Text>
        </Flex>
    },
    { label: 'Edit content', data: (item) => <IconAsButton tooltipLabel='edit course' iconName='CreateEdit' clickHandle={(e) => navigateToEditCourse(item)} /> },
    { label: 'Edit settings', data: (item) => <IconAsButton tooltipLabel='edit settings' iconName='Settings' clickHandle={(e) => navugateToCourseSettings(item)} /> },
    { label: 'View course', data: (item) => <IconAsButton tooltipLabel='view course' iconName='Preview' clickHandle={(e) => navigateToCourse(item)} /> },
  ]);

  if(!editableCourses.length) return null;

  return (<Flex width='100%' direction='column' align='flex-start' gap='20'>
      <Typo.ContentSmallHeader $textAlign='left'>Your courses</Typo.ContentSmallHeader>
      <ListTable
        tableInfo={tableInfo}
        data={editableCourses}
        // onRowClick={openCourse}
        tableName={'editable-courses'}
        tableSetter={setTableInfo}
        defaultRowPerPage={5}
      />
    </Flex>
  );
};

export default EditableCoursesList;