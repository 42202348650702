import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Typo, Loading, Filter, Icon, theme, ListTable, Modal } from '../../../ui';
import { STATUS_FAILED, STATUS_LOADING } from '../../../utils/constants';
import { deleteFile, moveFile, selectMediaTypes, selectStatus } from '../mediaSlice';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import SearchSelect from '../../../ui/SearchSelect';
import ConfirmDelete from '../../../ui/Modal/ConfirmDelete';

const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  padding: 1rem 0;
`

const MediaTable = ({ folders, mediaFiles, openFolder, selectedFolder, setSelectedFilter, selectedFilter, breadcrumbs }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mediaTypes = useSelector(selectMediaTypes);
  const mediaStatus = useSelector(selectStatus);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [folder, setFolder] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [mediaToDelete, setMediaToDelete] = useState(null);


  const handleMediaTypes = useCallback((value) => {
    setSelectedFilter(value);
  }, [setSelectedFilter]);

  const goToFile = useCallback(
    (file) => {
      navigate(`/media-library/${selectedFolder}/${file.id}`);
    },
    [selectedFolder, navigate]
  );

  const formatFileSize = useCallback((size) => {
    if (!size || size <= 0) return '—';
    if (size >= 1024 * 1024 * 1024) return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    if (size >= 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    if (size >= 1024) return `${(size / 1024).toFixed(2)} kB`;
    return `${size} bytes`;
  }, []);

  const renderIcon = useCallback((type) => {
    if (type === 'dir') return <Icon.Folder color={theme.colors.COURSIO_NEW} />;
    if (type === 'image') return <Icon.Image color={theme.colors.COURSIO_NEW} />;
    if (type === 'video') return <Icon.VideoFile color={theme.colors.COURSIO_NEW} />;
    if (type === 'audio') return <Icon.AudioFile color={theme.colors.COURSIO_NEW} />;
    return <Icon.File color={theme.colors.COURSIO_NEW} />;
  }, []);

  /** Combine folders and media files into a single data array **/
  const data = useMemo(() => {
    return [
      ...folders.map((folder) => ({ ...folder, type: 'dir' })),
      ...mediaFiles.map((file) => ({
        ...file,
        type: file.mime.split('/')[0], /** Extract type from mime (e.g., "image/png" -> "image") **/
      })),
    ];
  }, [folders, mediaFiles]);

  const openMoveModal = (item) => {
    setIsModalOpen(true);
    setSelectedFile(item);
  }

  /** Define columns for ListTable **/
  const tableInfo = useMemo(
    () => [
      {
        label: t('globals.name'),
        data: (item) => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {renderIcon(item.type)}
            <span>{item.name}</span>
          </div>
        ),
      },
      {
        label: t('globals.type'),
        data: (item) => (item.type === 'dir' ? t('mediaLibrary.folder') : item.type),
      },
      {
        label: t('globals.size'),
        data: (item) => (item.type === 'dir' ? '—' : formatFileSize(item.size)),
      },
      {
        label: t('globals.lastModified'),
        data: (item) => (item.created ? dayjs(item.created).format('DD MMMM YYYY') : '—'),
      },
      {
        label: 'Actions', menuOptions: (item) => [
          ...(item.type !== 'dir'
            ? [{ label: t('globals.move'), onClick: () => openMoveModal(item) }]
            : []),
          { label: t('globals.delete'), onClick: () => setMediaToDelete(item), variant: 'action' },
        ],
      },
    ],
    [t, renderIcon, formatFileSize]
  );

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
    setFolder(null);
  };

  const moveFileToFolder = () => {
    dispatch(moveFile({ directoryId: folder.id, id: selectedFile.id })).then(() => closeModal());
  }

  const handleMediaDelete = () => {
    dispatch(deleteFile({ id: mediaToDelete.id })).then(() => setMediaToDelete(null))
  }

  return (
    <div>
      {/** Filter Section **/}
      <FilterWrapper>
        <Filter info={mediaTypes} handleFilter={handleMediaTypes} selected={selectedFilter} />
      </FilterWrapper>

      {/** Table Section **/}
      {mediaStatus === STATUS_LOADING ? (
        <Loading />
      ) : mediaStatus === STATUS_FAILED ? (
        <Typo.Text $textAlign="center">{t('globals.genericError')}</Typo.Text>
      ) : (
        <ListTable
          fixed={true}
          tableInfo={tableInfo}
          data={data}
          tableName="mediaTable"
          onRowClick={(item) => (item.type === 'dir' ? openFolder(item) : goToFile(item))}
        />
      )}

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        width={'525px'}
        title={t('media.chooseFolder')}
        onAccept={moveFileToFolder}
        showActions
      >
        <Wrapper>
          <Typo.SmallText>{t('media.selectFolder')}</Typo.SmallText>
          <SearchSelect
            setValue={setFolder}
            selectedOptions={folder}
            id='users'
            options={
              folders.concat(
                [{ id: 0, name: t('mediaLibrary.rootFolderName') }].filter(() => selectedFile?.parentId)
              ).concat(breadcrumbs.slice(0, -1))
            }
            renderOption={(props, option) => {
              return <li {...props} key={option.id || option}>
                <Typo.Text >{option.name}</Typo.Text>
              </li>
            }}
          />
        </Wrapper>
      </Modal>

      {!!mediaToDelete?.id && <ConfirmDelete
        onClose={setMediaToDelete}
        onAcceptDelete={handleMediaDelete}
        open={!!mediaToDelete?.id}
        description={t('mediaLibrary.deleteTargetConfirmation',
          { target: mediaToDelete.type === 'dir' ? t('mediaLibrary.folder') : t('mediaLibrary.file') })}
      />}
    </div>
  );
};

export default MediaTable;
