import React from 'react';
import ListCourses from '../../../components/ListCourses';
import DashboardCourseCard from '../../../ui/CourseCard/DashboardCourseCard';
import { Flex, Typo } from '../../../ui';

const SearchResults = ({courses}) => {  

  return (<Flex width='100%' direction='column' align='flex-start' gap='20'>
      <Typo.ContentSmallHeader $textAlign='left'>Search results</Typo.ContentSmallHeader>
      <ListCourses showProgress={true} showButtons={false} module={module} items={courses} CardComponent={DashboardCourseCard} />
    </Flex>
  );
};

export default SearchResults;