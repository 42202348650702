import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typo } from '../../../../ui';

const Wrapper = styled.div`
  width:100%;
`;

const NoAccessPage = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Typo.ContentSmallHeader $textAlign='left'>{t('noAccess.title')}</Typo.ContentSmallHeader>
      <Typo.Text>{t('noAccess.description')}</Typo.Text>
      <Typo.Text>{t('noAccess.supportEmail')}</Typo.Text>
    </Wrapper>
  );
};

export default NoAccessPage;
