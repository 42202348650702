import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import emailReg from '../../../../helpers/emailValidation';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripeKey = process.env.REACT_APP_STAGE === 'prod' ? 'pk_live_cJIzjNJJArhwBq1nS1KURscP' : 'pk_test_B1tMc6U3rWXMk3IcedxuZazF';
const stripePromise = loadStripe(stripeKey);

/* eslint-disable-next-line */
const Message = ({ message }) => (
  <div>
    <p>{message}</p>
  </div>
);

export default function StripeCheckout({ sessionId, paymentEmail, personalInfoAgreement }) {
  const { t } = useTranslation();
  /* eslint-disable-next-line */
  const [message, setMessage] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage(t('stripe.orderPlaced'));
    }

    if (query.get("canceled")) {
      setMessage(t('stripe.orderCanceled'));
    }
  }, [t]);

  const stripeRedirect = async () => {
    const stripe = await stripePromise;

    const result = await stripe.redirectToCheckout({
      sessionId: sessionId,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  return sessionId && emailReg.test(paymentEmail) ? stripeRedirect() : null;
}
