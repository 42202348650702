import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'
import { accessApi, courseApi, userApi } from '../../utils/urls';
//LEGACY
import CourseStore from '../../../../legacy/src/js/stores/Course';
//
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import { enqueueSnackbar } from 'notistack'
import { ucpPeriods } from '../../utils/ucpPeriods';
import httpClient from '../../services/httpClient';
import i18n from 'i18next';

// ----------------- Thunks -----------------------------

export const fetchCountByCourseId = createAsyncThunk('course/fetchCountByCourseId', async ({ courseId }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      courseId
    },
    method: 'countByCourseId'
  };

  const res = await httpClient.post(userApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});

export const fetchCourseById = createAsyncThunk('course/fetchCourseById', async ({ courseId }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      id: courseId,
    },
    method: 'findById'
  };

  const res = await httpClient.post(courseApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});

export const fetchCourseByUserSlag = createAsyncThunk('course/fetchCourseByUserSlag', async ({ username, slugName }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      slug: slugName,
      username,
      countStudents: true,
    },
    method: 'findByUsernameAndSlug'
  };

  const res = await httpClient.post(courseApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});

export const createCourse = createAsyncThunk('course/createCourse', async ({ name, coverImage }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      name,
      coverImage
    },
    method: 'create'
  };

  const res = await httpClient.post(courseApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
});

export const updateCourse = createAsyncThunk('course/updateCourse', async ({ courseData }, { getState, dispatch, rejectWithValue }) => {
  // update needs to always send descrition and categories in courseData or those will be overwritten
  const body = {
    data: courseData,
    method: 'update'
  };

  const res = await httpClient.post(courseApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});

export const deleteCourse = createAsyncThunk('course/deleteCourse', async (id, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { id },
    method: 'delete'
  };

  const res = await httpClient.post(courseApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
});

export const toggleCourseDraft = createAsyncThunk('course/toggleCourseDraft', async ({ id }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { id },
      method: 'toggleDraft'
  };

  const res = await httpClient.post(courseApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
});

export const duplicateCourse = createAsyncThunk('course/duplicateCourse', async (id, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { id },
    method: 'duplicate'
  };

  const res = await httpClient.post(courseApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
});
export const changeAccessToCourse = createAsyncThunk('course/changeAccessToCourse', async ({ id, expires, userId, mode }, { getState, dispatch, rejectWithValue }) => {
  // mode => Options: 'reader', 'builder'
  const body = {
    data: { id, expires, userId, mode },
    method: 'course'
  };

  const res = await httpClient.post(accessApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
});

// ----------------- Reducers ---------------------------

const initialState = {
  data: {}
};

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    clearCourse(state) {
      state.data = {};
      state.status = 'idle';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createCourse.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(createCourse.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        enqueueSnackbar(i18n.t('course.createdSuccess'), { variant: TOAST_SUCCESS });
      })
      .addCase(updateCourse.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateCourse.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(updateCourse.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        enqueueSnackbar(i18n.t('course.updatedSuccess'), { variant: TOAST_SUCCESS });
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(deleteCourse.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        enqueueSnackbar(i18n.t('course.deletedSuccess'), { variant: TOAST_SUCCESS });
      })
      .addCase(toggleCourseDraft.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(toggleCourseDraft.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data = { ...state.data, ...action.payload };
        enqueueSnackbar(i18n.t('course.draftUpdated'), { variant: TOAST_SUCCESS })
      })
      .addCase(duplicateCourse.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(duplicateCourse.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        enqueueSnackbar(i18n.t('course.duplicatedSuccess'), { variant: TOAST_SUCCESS })
      })
      .addCase(changeAccessToCourse.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(changeAccessToCourse.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        enqueueSnackbar(i18n.t('course.accessUpdated'), { variant: TOAST_SUCCESS })
      })
      .addCase(fetchCourseByUserSlag.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchCourseByUserSlag.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchCourseByUserSlag.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;

        // LEGACY UPDATE
        const legacyGlobalData = CourseStore.getInitialState();
        legacyGlobalData.course = action.payload;
        legacyGlobalData.page = {};
        CourseStore.triggerForLegacy(legacyGlobalData)
        // FINISH LEGACY UPDATE
        state.data = action.payload;
      })
      .addCase(fetchCourseById.pending, (state, action) => {
        state.status = STATUS_LOADING;
        // Do not clear state.data here to avoid flickering
        // state.data = {}
      })
      .addCase(fetchCourseById.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchCourseById.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        const { diploma, quiz } = action.payload?.settings;
        const hasDiploma = diploma?.fileId || quiz?.diplomaByQuizProgress
        const progress = action.payload.progress *100;
        const minProgress = diploma?.passPercentage || 80;
        const coursedata = hasDiploma ? {...action.payload, toc:[...action.payload.toc,
          {id:'Diploma', type:'diploma', progress: progress >= minProgress ? 1 : 0,
            name:'Diploma',
            pages:[{id:'diploma', progress: 0,
              name: progress >= minProgress ? 'Get your Diploma' : 'Diploma info'}]}]
          }
          : {...action.payload}

        state.data = { ...state.data, ...coursedata };
      })
      .addCase(fetchCountByCourseId.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data.totalUsers = action.payload;
      })
  }
})

export const { clearCourse } = courseSlice.actions;

export default courseSlice.reducer

// ----------------- Selectors --------------------------
export const selectCourseStatus = state => state.course.status;
export const selectCourse = state => state.course.data;
export const selectCourseId = state => state.course.data.id;
export const selectCourseToc = state => state.course.data.toc;
export const selectCourseTheme = state => state.course.data.theme;
export const selectCourseQuiz = state => {
  const allQuizes = state.course.data.toc?.filter(element => element.type === 'quiz')
  return allQuizes?.reduce((acc, el) => {
    acc = [...acc, el.pages[0]]
    return acc;
  }, [])
}
export const selectCourseQuizSettings = state => state.course.data.settings?.quiz?.pages
export const selectUsersAmount = state => state.course.data.totalUsers;

const selectHasUnlimitedDuration = (state) => state.settings.noCourseExpire;
const selectShowUnlimited = (state, hideUnlimited) => hideUnlimited
export const selectCourseDurations = createSelector(
  [selectHasUnlimitedDuration, selectShowUnlimited],
  (hasUnlimitedDuration, hideUnlimited) => {
    const allDurations = [...ucpPeriods]
    if (!hasUnlimitedDuration || hideUnlimited) allDurations.shift()
    return allDurations
  }
)

const secondsInAnHour = 60 * 60;

const periodMultipliers = {
  0: 1,
  1: 24,
  2: 30 * 24
};

export const courseAccessTimePeriod = [
  { value: 'hour', text: 'Hours' },
  { value: 'day', text: 'Days' },
  { value: 'month', text: 'Months' }
]

export const secondsToPeriod = seconds => {
  let selectedIndex = 0;
  let amount = 0;
  Object.keys(periodMultipliers)
    .sort((a, b) => b - a)
    .forEach(index => {
      if (
        amount < 1 &&
        seconds % (secondsInAnHour * periodMultipliers[index]) === 0
      ) {
        amount = seconds / (secondsInAnHour * periodMultipliers[index]);
        selectedIndex = parseInt(index, 10);
      }
    });
  return { type: courseAccessTimePeriod[selectedIndex].value, amount };
};

