import React, { useCallback } from 'react';
import styled from 'styled-components';
import DashboardCourseCard from '../../../ui/CourseCard/DashboardCourseCard';
import { Flex, theme } from '../../../ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Keyboard } from 'swiper';
import { useNavigate } from 'react-router';


const SwiperStyled = styled(Swiper)`
 
  .swiper-button-prev,
  .swiper-button-next{
    color: ${theme.colors.PRIMARY};
    &:after{
      background-color:${theme.colors.NEUTRAL_0};
      padding:10px 15px;
   
      font-size:24px;
      font-weight: bold;
      border-radius:21px;
      opacity:0.5;
    }

    &:hover:after{ opacity:1; }
  }

  .swiper-slide {
    width:375px;
  }

  &.swiper-horizontal {
    margin:unset;
  }

`

const CoursesListCarousel = ({courses}) => {

  const navigate = useNavigate();

  const goToCourse = useCallback((item) => {
    navigate(`/view/course/${item.id}`)
  }, [navigate]);

  const goToEdit = useCallback((item) => {
    navigate(`/course/${item.ownerSlug}/${item.slug}`, { state: item });
  }, [navigate]);

  const goToCourseSettings = useCallback((item) => {
    navigate(`/courses/${item.id}/settings`);
  }, [navigate]);

  return (
    <Flex width='100%'>
      <SwiperStyled
        modules={[ Navigation, Keyboard]}
        pagination={{ clickable: false }}
        keyboard={{ enabled: true }}
        navigation={true}
        lazy='true'
        slidesPerView={'auto'}
        spaceBetween={30}
      >
        {courses.map((item, index) =>
        <Flex width='375px' key={`course-container${item.id}-${index}`}>
          <SwiperSlide key={`course-wrapper${item.id}-${index}`}>
            <DashboardCourseCard
              key={`course-card${item.id}-${index}`}
              courseInfo={item}
              goToCoursePage={goToCourse}
              goToSettings={goToCourseSettings}
              goToEditCourse={goToEdit}
            />
          </SwiperSlide>
        </Flex>
        )}
      </SwiperStyled>
    </Flex>
  );
};

export default CoursesListCarousel;