import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { storeApi, courseApi, coursePackageApi } from '../../utils/urls';
import { nanoid } from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import { enqueueSnackbar } from 'notistack'
import httpClient from '../../services/httpClient';
import { viewItemGa } from '../../helpers/GATracker';
import i18next from "i18next";

// ----------------- Thunks -----------------------------

//WHEN SAVING LAYOUT:  we need to make sure we dont delete any other data that it is inside settings

export const fetchCourse = createAsyncThunk('course/fetchCourse', async ({ storeName, slugName, type }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      discountCode: null,
      slug: slugName,
      type,
      toc: 1,
      username: storeName
    },
    method: 'findBySlug'
  };

  const res = await httpClient.post(storeApi(), body, { getState, dispatch, rejectWithValue, noToken:true });

  return res.data;
})

export const fetchCourseById = createAsyncThunk('course/fetchCourseById', async ({ courseId, isPackage }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      id: courseId,
      type: isPackage ? 2 : 1
    },
    method: 'findById'
  };

  const res = await httpClient.post(storeApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
})

export const toggleCourseVisibility = createAsyncThunk('course/toggleVisibility', async ({ courseId, isPackage }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      id: courseId
    },
    method: 'toggleVisibility'
  };

  const url = !isPackage ? courseApi() : coursePackageApi();

  const res = await httpClient.post(url, body, { getState, dispatch, rejectWithValue });

  const message = res.isError ? res.errMsg : i18next.t('course.toggleVisibility')
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

export const createCoursePackage = createAsyncThunk('course/createCoursePackage', async ({ newData }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: newData,
    method: 'create'
  };

  const res = await httpClient.post(coursePackageApi(), body, { getState, dispatch, rejectWithValue });

  const message = res.isError ? res.errMsg : i18next.t('course.createdPackageSuccess')
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data
})
export const clearCoursePackageSequence = createAsyncThunk('course/clearCoursePackageSequence', async ({ id }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { id },
    method: 'clearSequence'
  };

  const res = await httpClient.post(coursePackageApi(), body, { getState, dispatch, rejectWithValue });
  return res.data
})

export const saveCourse = createAsyncThunk('course/saveCourse', async ({ newData, isPackage }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: newData,
    method: 'update'
  };
  const url = !isPackage ? courseApi() : coursePackageApi();
  const res = await httpClient.post(url, body, { getState, dispatch, rejectWithValue });

  const message = res.isError ? res.errMsg : i18next.t('course.settingsSaved')
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data
})

export const deleteCoursePackage = createAsyncThunk('course/deleteCoursePackage', async (id, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { id },
    method: 'delete'
  };

  const res = await httpClient.post(coursePackageApi(), body, { getState, dispatch, rejectWithValue });

  const message = res.isError ? res.errMsg : i18next.t('course.deletedPackageSuccess')
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

// ----------------- Reducers ---------------------------

const mockId = (data) => data?.map(el => { return { ...el, id: nanoid() } });

const initialState = {
  id: null,
  status: 'idle', // or: 'loading', 'succeeded', 'failed'

  teacher: {}, //name:'', about:'', avatarUrl:''},
  highlightedInfo: {}, //title:'', text:'', image:{id:'', url:''}},
  coverMedia: { video: { id: null, url: null }, image: { id: null, url: null } },//video:{id:'', url:''}, image:{id:'', url:''}}, //image is not editable only when creating course
  ctaBanner:{}, //title:'', descrition:''
  introduction: {},//title: '' }, Title is not editable only when creating course
  infoIcons: [],//{iconName:'', title:'', text:'', id:''}],
  infoPoints: [],//{title:'', text:'', id:''}],
  courseContent: {}, //do not present raw toc but allow to edit the toc to set proper description
  courseToc: {},
  customFile: {}, // {id: '', url: '', name: ''}
  sectionTitles: {},

  price: {},//{gross:'', net:'', discounted:''}
  ucpPeriod: 2592000,
  currency: 'SEK',
  isPublic: 0,

  dontDeleteSettings: {},

  testimonies: [],//{image:'', name:'', text:''}]
  cta: ''
  // emailGatherer:'',

}

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(toggleCourseVisibility.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(toggleCourseVisibility.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(fetchCourse.pending, (state, action) => {
        return { ...initialState, status: STATUS_LOADING };
        // state.status = STATUS_LOADING;
      })
      .addCase(fetchCourse.rejected, (state, action) => {
        state.status = STATUS_FAILED
      })
      .addCase(fetchCourse.fulfilled, (state, action) => {
        if (!action.payload) {
          state.status = STATUS_FAILED

        } else {

          //GA
          viewItemGa({
            id: action.payload.id,
            name: action.payload.name,
            price: action.payload.price.discounted,
            quantity: 1
          })

          state.id = action.payload.id

          const {coverImage, currency, name, type, courses, categories, description } = action.payload

          //If course is a bundle, coverImage can have more than one object.
          if (coverImage[0]) state.coverMedia = { image: coverImage[0] };

          if (currency) state.currency = currency;
          if (description) state.description = description;
          if (categories) state.categories = categories.map(category => category.slug);

          if (name) state.introduction = { ...state.introduction, title: name }
          const isBundle = type === 2

          if (!isBundle && courses) state.courseToc = courses[0].toc

           // If it is a bundle we will have several courses:
          const parseCourseInBundleLayout = (settings) => {
            const info = {}
            const { teacher, highlightedInfo,
              infoIcons, infoPoints, testimonies, introduction, customFile } = settings.layout

            info.teacher = teacher;
            info.highlightedInfo = highlightedInfo;
            info.infoPoints = infoPoints;
            info.infoIcons = mockId(infoIcons);
            info.infoPoints = mockId(infoPoints);
            info.testimonies = testimonies;
            info.introduction = introduction;
            info.customFile = customFile

            return info
          }

          if (isBundle) {
            state.packageItems = courses.map((item) => {

              const settingsData = item.config;
              let newCourseLayout = {};
              if (settingsData?.layout && Object.keys(settingsData?.layout)?.length )
                  newCourseLayout = parseCourseInBundleLayout(settingsData)

              newCourseLayout.courseContent = item.toc

              return {...item, settings: {...settingsData, layout: newCourseLayout}}
            })
          }

          // EDITABLE INFORMATION IN NEW LAYOUT
          if (action.payload.videoUrl) state.coverMedia = { ...state.coverMedia, video: { url: action.payload.videoUrl } }
          if (action.payload.price) state.price = action.payload.price
          if (action.payload.ucpPeriod) state.ucpPeriod = action.payload.ucpPeriod
          if (action.payload.public) state.isPublic = action.payload.public

          const data = isBundle ?
            action.payload.layout :
              action.payload?.settings.length && JSON.parse(action.payload?.settings)?.layout

          if (data) {
            const { teacher, highlightedInfo, infoIcons, infoPoints, hideToc,
              testimonies, courseCoverMedia, ctaBanner, sectionTitles, customFile } = data;

            // VARIABLES UNIQUE TO NEW LAYOUT
            state.coverMedia = Array.isArray(courseCoverMedia) ? { video: {}, image: {} } : courseCoverMedia;
            state.teacher = teacher;
            state.highlightedInfo = highlightedInfo;
            state.infoPoints = infoPoints;
            state.infoIcons = infoIcons ? mockId(infoIcons) : [];
            state.infoPoints = infoPoints ? mockId(infoPoints) : [];
            state.courseContent = state.courseToc;
            state.testimonies = testimonies;
            state.sectionTitles = sectionTitles;
            state.ctaBanner = ctaBanner;
            state.hideToc = hideToc;
            state.customFile = customFile;
          }

          state.status = STATUS_SUCCEEDED
        }

      })
      .addCase(saveCourse.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(saveCourse.rejected, (state, action) => {
        state.status = STATUS_FAILED
      })
      .addCase(saveCourse.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED
        state.course = action.payload;
      })
  }
})

export default courseSlice.reducer

// ----------------- Selectors --------------------------
export const selectCourseCoverMedia = state => state.webshop.course.coverMedia;
export const selectCtaBanner = state => state.webshop.course.ctaBanner
export const selectLayout = state => state.webshop.course;
export const selectCourseIsPublic = state => state.webshop.course.isPublic;
export const selectLayoutId = state => state.webshop.course.id;
export const selectStatus = state => state.webshop.course.status;

