import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Typo, Loading, Icon } from '../../../ui';
import { STATUS_LOADING } from '../../../utils/constants';
import { selectStatus } from '../mediaSlice';
import DropableFolder from './DropableFolder';
import Folder from './Folder';

const FolderHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap:10px;
`;

const FolderList = styled.div`
  display:${({ hidden }) => hidden ? 'none' : 'flex'};
  gap:10px;
  flex-wrap:wrap;
  margin-top: 20px;
`;

const FoldersDirectory = ({ data, openFolder, selectedFolder, withDND }) => {
  const { t } = useTranslation();

  const mediaStatus = useSelector(selectStatus)
  const [hiddenFolders, setHiddenFolders] = useState(false);

  const toggleContent = () => {
    setHiddenFolders(!hiddenFolders)
  }

  return (
    <div >
      <FolderHeader onClick={toggleContent}>
        <Typo.ContentSmallHeader>{t('mediaLibrary.folders')}</Typo.ContentSmallHeader>
        {hiddenFolders ? <Icon.ExpandMore /> :
          <Icon.ExpandLess />}
      </FolderHeader>
      { mediaStatus === STATUS_LOADING ? <Loading /> :
        <FolderList hidden={hiddenFolders}>
          {data?.map(folder =>
            <div key={folder.id}>
              {withDND ? <DropableFolder data={folder} openFolder={openFolder} isSelected={selectedFolder === folder.id} />
                : <Folder data={folder} openFolder={openFolder} isSelected={selectedFolder === folder.id} />}
            </div>
          )}
        </FolderList>
      }
    </div>
  )
}

export default FoldersDirectory;
