import { enqueueSnackbar } from 'notistack';
import { authStatus, selectAuthToken } from '../features/auth/authSlice';
import { TOAST_ERROR } from '../utils/constants';
import i18next from 'i18next';

const httpClient = {
  post: (url, body, { getState, dispatch, rejectWithValue, noRedirect = false, responseType = 'json', noToken = false }) => {
    const t = i18next.t;
    const authToken = selectAuthToken(getState());

    if (!url) {
      return enqueueSnackbar(t('httpClient.urlRequired'), { variant: TOAST_ERROR });
    }

    if (!noToken && !authToken) {
      enqueueSnackbar(t('httpClient.invalidToken'), { variant: TOAST_ERROR });
    } else {
      const init = {
        method: 'POST',
        headers: { token: authToken },
        body: body instanceof FormData ? body : JSON.stringify(body),
      };

      return fetch(url, init).then(async res => {
        if (!res.ok) {
          if (res.status === 500) {
            !noRedirect && enqueueSnackbar(t('httpClient.serverError'), { variant: TOAST_ERROR });
            return { data: rejectWithValue({ errorCode: res.status, response: await res.json() }) };
          } else if (res.status < 200 || res.status >= 300) {
            if (res.status === 401) {
              dispatch(authStatus());
            }
            return { data: rejectWithValue({ errorCode: res.status, response: await res.json() }) };
          } else {
            console.error(res);
            throw Error(t('httpClient.fetchError', { url }));
          }
        }

        if (responseType === 'json') {
          return res.json().then(json => {
            if (json.reason && json.time) {
              throw Error(json.reason);
            }
            return json;
          });
        } else {
          return res;
        }
      });
    }
  }
};

export default httpClient;
